import React from 'react';
import { IonCol, IonLabel, IonRow} from '@ionic/react';

const WellDetailsRow = (props) =>{
    const render = () => {
        if (props.value && props.value !== "null") {
            return (
                <IonRow class="ion-padding" >
                    <IonCol  size="4">
                        <IonLabel  color="grey">{props.caption}
                        </IonLabel>
                    </IonCol>
                    <IonCol class="ion-text-right">
                        <IonLabel color="primary">{props.value}
                    </IonLabel></IonCol>
                </IonRow>
            )
        }
        else { return <div /> }
     }
     return (render())
}

export default WellDetailsRow;
