import React, {Suspense} from 'react';
import AuthCard from '../../components/Shared/AuthCard';
import { IonContent } from '@ionic/react';
import Loading from '../../components/Shared/Loading'
import ModalHeader from '../../components/Shared/ModalHeader';
//const AuthCard = React.lazy(()=>import('../../components/Shared/AuthCard'));


const SignUpModal = (props) => {

  return (
    <>
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle>
            Login or Create Account 
          </IonTitle>
          <IonButtons slot="end">
            <IonButton strong onClick={props.dismissModal}>Done</IonButton>
          </IonButtons>
        </IonToolbar>

      </IonHeader> */}
      <ModalHeader title="Login or Create Account" dismissModal={props.dismissModal}></ModalHeader>
      <IonContent>
      <Suspense  fallback = {<Loading/>}>
        <AuthCard type='Signin' isFromModal={true}  dismissModal = {props.dismissModal}
        ></AuthCard>
        </Suspense>
      </IonContent>
    </>
  )
};

export default SignUpModal;