import  React from 'react';
import {IonContent, IonSpinner} from '@ionic/react'

const Loading = ()=>{
    return(
        <IonContent class="ion-text-center">
        <p>Loading...</p>
        <IonSpinner></IonSpinner>
        </IonContent>
    )
}

export default Loading;