import React, {useContext} from 'react';
import { ContextOne } from '../../store/ContextOne'
import {
  IonIcon, IonMenu, IonHeader, IonToolbar,
  IonContent, IonList, IonItem,
  IonLabel, IonMenuToggle, IonListHeader
} from '@ionic/react';
import { withRouter } from 'react-router';
import AppName from '../Shared/AppName';
import * as actionTypes from '../../store/ActionsTypes'
import { options, logOut, personAdd, paper, logIn } from 'ionicons/icons';

const routes = {
  appPages: [
    { title: 'Feeds', path: '/', icon: paper },
    { title: 'Preferences', path: '/preferences', icon: options },
    // { title: 'Feedback', path: '/feedback', icon: 'paper-plane' },
  ],
  loggedInPages: [
    // { title: 'Account', path: '/account', icon: 'person' },
    // { title: 'Support', path: '/support', icon: 'help' },
    { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    // { title: 'Support', path: '/support', icon: 'help' },
    { title: 'Create Account', path: '/signup', icon: personAdd }
  ]
}

const Menu = (props) => {

   let { state, dispatch } = useContext(ContextOne);
   React.useEffect(() => {
       
  },[state.isAuthenticated] );
   
   let onClickHandler = (path)=>{
    if (path === '/logout')
        fireLogOut();
    else{
      props.history.push(path);
    }
  }
  const fireLogOut = ()=>{
      dispatch({type: actionTypes.LOGOUT});
      props.history.push('/');
  };

  

  function renderlistItems(list) {
       return list
      .filter(route => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          {/* <IonItem button onClick={() => props.history.push(p.path)}> */}
          <IonItem button onClick={()=> onClickHandler(p.path)}> 
            <IonIcon slot="start" icon={p.icon}></IonIcon>
            <IonLabel>
              {p.title}
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
    ));
  }

 

  return (
    <IonMenu contentId="main">
      <IonHeader>
        <IonToolbar>
          <AppName />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList>
          <IonListHeader>
            Account
          </IonListHeader>
          {state.isAuthenticated ?
            renderlistItems(routes.loggedInPages) :
            renderlistItems(routes.loggedOutPages)}
        </IonList>
      </IonContent>
    </IonMenu>
  );
}

export default withRouter(Menu);
