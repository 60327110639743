export const getDatePretty = (dateSTR) => {

    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let datetime = new Date(dateSTR + " 12:00:00");
    let formatted_date = JSON.stringify(months[datetime.getMonth()] + " " + datetime.getDate().toString() + ", " + datetime.getFullYear().toString());
    return formatted_date
}

export const getDateRangeValue = (dateSelection) => {
    let startDate = new Date();
    let endDate = new Date();
    let result = {startDate: startDate, endDate: endDate};
    
        
        switch (dateSelection) {
            case "today": {
                startDate.setDate(startDate.getDate());
                break;
            }
            case "yesterday": {
                startDate.setDate(startDate.getDate() - 1);
                endDate = startDate;
                break;
            }

            // case "Last 3 days": {
            //    startDate.setDate(startDate.getDate() - 2);
            //     break;
            // }

            case "last_7_days": {
                startDate.setDate(startDate.getDate() - 6);
                break;
            }

            case "last_15_days": {
                startDate.setDate(startDate.getDate() - 14);
                break;
            }
            // case "Next 15 days": {
            //     endDate.setDate(endDatete.getDate() + 14);
            //     break;
            // }
            // case "Last 30 days": {
            //     startDate.setDate(startDate.getDate() - 29);
            //     break;
            // }
            // case "Next 30 days": {
            //     oc.EndDate.setDate(oc.StartDate.getDate() + 29);
            //     break;
            // }
            // case "Last 60 days": {
            //     startDate.setDate(startDate.getDate() - 59);
            //     break;
            // }
            // case "Next 60 days": {
            //     endDate.setDate(endDatete.getDate() + 59);
            //     break;
            // }
            // case "Last 90 days": {
            //     endDatete.setDate(endDatete.getDate() - 89);
            //     break;
            // }
            // case "Next 90 days": {
            //     endDate.setDate(endDatete.getDate() + 89);
            //     break;
            // }
            // case "Next 120 days": {
            //     startDate.setDate(startDate.getDate() + 119);
            //     break;
            // }
            // case "Next 6 months": {
            //     var next6 = endDate.getMonth() + 6;
            //     endDate.setMonth(next6);
            //     break;
            // }
            // case "Last 120 days": {
            //     startDate.setDate(startDate.getDate() - 119);
            //     break;
            // }
            default: {
                startDate.setDate(startDate.getDate() - 7);
                break;
            }
        }
        //console.log("dateresult",startDate,endDate)
        result.startDate = toShortDate(startDate);
        result.endDate = toShortDate(endDate);
    return result;
}

export const toShortDate = ((dateValue) => {
    //const currentMonth = dateValue.getMonth() + 1;
    //console.log("converting",(dateValue.getMonth() + 1).toString().padStart(2,"0"));
    return dateValue.getFullYear().toString() + "-" +(dateValue.getMonth() + 1).toString().padStart(2,"0") + "-" + dateValue.getDate().toString().padStart(2,"0");
});

//export const getDisplayDate =(year, month, day)=> {
    //     let today = new Date();
    //     today.setHours(0);
    //     today.setMinutes(0);
    //     today.setSeconds(0);
    //     today.setMilliseconds(0);
    //     let compDate = new Date(year,month-1,day); // month - 1 because January == 0
    //     let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
    //     if (compDate.getTime() === today.getTime()) {
    //         return "Today";
    //     } else if (diff <= (24 * 60 * 60 *1000)) {
    //         return "Yesterday";
    //     } else { 
    //         //return compDate.toDateString(); // or format it what ever way you want
    //         year = compDate.getFullYear();
    //         month = compDate.getMonth();
    //         months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
    //         day = compDate.getDate();
    //         d = compDate.getDay();
    //         days = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat');
    
    //         var formattedDate = days[d] + " " + day + " " + months[month] + " " + year;
    //         return formattedDate;
    //     }
