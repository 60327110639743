import React from 'react';
import  Helmet  from "react-helmet-async";

const HelmetSEO = (props) =>{
    return(
        <Helmet>
            { props.title?<title content={props.title + " - GeoActivity Feeds"} data-rh="false"></title>:null}
            { props.description?<meta name="description" content={props.description} data-rh="false"></meta>: null} 
            { props.description?<meta name="og:description" content={props.description} data-rh="false"></meta>: null}                
        </Helmet>
    )
}

export default HelmetSEO;