import React, {Suspense} from 'react';
import {
  IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle
} from '@ionic/react';
import AuthCard from '../../components/Shared/AuthCard';
import Loading from '../../components/Shared/Loading'
//const AuthCard = React.lazy(()=>import('../../components/Shared/AuthCard'));


const LoginPage = () => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <Suspense fallback={<Loading/>}>
       <AuthCard type='Signin' isFromModal={false}></AuthCard>
      </Suspense>
    </>
      );
    };
    
export default LoginPage;