import React from 'react';
import {IonRow, IonCol, IonLabel, IonBadge} from '@ionic/react';

// const FeedDetailsPage = React.lazy(()=>import('../pages/FeedDetailsPage/FeedDetailsPage'));

const FeedTopsTable = (props)=>{

    return (
        
            props.feed.tops.map(topItem => {
                return (
                    <IonRow key={topItem.name} >
                        <IonCol text-left size="10" >
                            <IonLabel style={{marginLeft:"10px"}}>{topItem.name}</IonLabel>
                        </IonCol>
                        <IonCol justify-content-end text-end size="2">
                            <IonBadge color="light">{topItem.value}</IonBadge>
                        </IonCol>
                    </IonRow>
                )
            })
        
    );
}

export default FeedTopsTable;