import React, {Suspense} from 'react';
import FeedDetails from '../../components/Feeds/FeedDetails/FeedDetails';
import { withRouter} from 'react-router-dom';
import Loading from '../../components/Shared/Loading';
//const FeedDetails = React.lazy(()=>import('../../components/Feeds/FeedDetails/FeedDetails'));

const FeedDetailsPage = (props) => {
  return(
     <Suspense fallback={<Loading/>}>
       <FeedDetails></FeedDetails>
    </Suspense>
  )
}

export default withRouter(FeedDetailsPage);