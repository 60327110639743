import React from 'react';
import { withRouter } from 'react-router-dom';
import {IonHeader,  IonButtons, IonToolbar,  IonTitle, IonBackButton} from '@ionic/react';
import {useLastLocation} from 'react-router-last-location';

const PageBackHeader =React.memo((props)=>{
    const lastLocation = useLastLocation();
    const  handleGoBack = (e) => {
        try {
            if (lastLocation){
               // console.log(props.history);
                props.history.goBack();
            }
            else
            window.open("https://feeds.geoactivity.com","_self");
          }
          catch(error) {
            console.log(error);
          }
          
    }

    return(
        <IonHeader>
            <IonToolbar>
                  <IonButtons slot="start">
                   {/* {  lastLocation
                      ?  */}
                        <IonBackButton defaultHref={'https://feeds.geoactivity.com/'} onClick={(e)=>{handleGoBack()} } />
                       {/* : <IonButton onClick={(e)=>{ props.history.push('/')} }>
                       <ion-icon slot="end" name="arrow-back"></ion-icon>
                       </IonButton> */}
                   {/* }  */}
                   </IonButtons>
                 <IonTitle>{props.headerText}</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
})

export default withRouter(PageBackHeader);