import React from 'react';
import { IonRow, IonList, IonToast, IonCol, IonChip, 
    IonIcon, IonInput, IonButton, IonGrid, IonLabel, 
    IonContent, IonTextarea} from '@ionic/react';
import FireBase from '../../config/FireBase';
//import { ContextOne } from '../../store/ContextOne';
import { withRouter } from 'react-router-dom';
//import * as actionTypes from '../../store/ActionsTypes';
import {mail, lock} from "ionicons/icons"


const AuthCard = (props) => {

    ////let { dispatch } = React.useContext(ContextOne);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [type, setType] = React.useState(props.type);
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {

    }, [type]);

    const renderButtons = () => {
        if (type !== 'Signup') {
            return (
                <IonRow>
                    <IonCol class="ion-text-right ion-padding-end">
                        <IonButton  type='submit' onClick={() => setType("Signin")}>
                            Login
                        </IonButton>
                    </IonCol>
                    <IonCol class="ion-text-left ion-padding-start">
                        <IonButton color="light" onClick={() => {setType("Signup")}} >
                            Signup
                        </IonButton>
                    </IonCol>
                </IonRow>
            )
        }
        else {
            return (
                <>
                    <IonRow lines="none" class="ion-justify-content-center ion-padding">
                        <IonButton class="ion-justify-content-center ion-padding" type="submit">
                            Create Account
                        </IonButton>
                    </IonRow>
                </>
            )
        }
    }

    const handleSignup = (event) => {
        event.preventDefault();
        if (validateForm()) {
          FireBase.auth()
            .createUserWithEmailAndPassword(email, password)
            .then(() => {
              setSuccess(true);
              if (props.isFromModal) props.dismissModal();
            })
            .catch(error => {
                errors["password"] = error.message;
            //   if (error.code === "auth/email-already-in-use")
            //     errors["password"] = error.message;
            //   else
            //     errors["password"] =
            //       "There is an error in the email or password";
              setErrors(errors);
            });
        }
    }

    const handleSignin = event => {
      event.preventDefault();
      if (type === "Signin") {
        if (validateForm()) {
          FireBase.auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              setSuccess(true);
              if (props.isFromModal) props.dismissModal();
            })
            .catch(error => {
              errors["password"] = error.message;
              setErrors(errors);
            });
        }
      } else {
        handleSignup(event);
      }
    };

    const validateForm = () => {

        let errors = {};
        let formIsValid = true;

        if (!email) {
            formIsValid = false;
            errors["email"] = "*Please enter your email.";
        }


        if (typeof email !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email.";
            }
        }

        if (typeof password !== "undefined") {
            if (password.length < 6) {
                formIsValid = false;
                errors["password"] = "*Please enter minimum of 6 characters password.";
            }
        }

        if (!password) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleEmailTyping = (event) => {
        setEmail(event.detail.value);
        setErrors({});
    }
    const handlePasswordTyping = (event) => {
        if (event.detail.value !== password) {
          setPassword(event.detail.value);
        }
        setErrors({});
    }

    return (
        <IonContent>
            <IonGrid style={{ height: "100%" }}>
                <IonRow class="ion-justify-content-center">
                    <IonCol>
                        <IonList>
                            <IonRow class="ion-justify-content-center" >
                                <div style={{ width: 200, height: 200 }}>
                                    <img src="/assets/img/logo.svg" alt="GeoActivity Feeds logo" />
                                </div>
                            </IonRow>
                            <IonRow class="ion-text-center">
                                <IonCol  class="ion-text-center ion-justify-content-center">
                                    <form type='summit' onSubmit={(event) => handleSignin(event)}>
                                        <div>
                                            <IonChip outline>
                                                <IonIcon icon={mail}>email</IonIcon>
                                                <IonInput placeholder="Email address" text-start 
                                                    onIonChange={handleEmailTyping}
                                                    type="email"
                                                    autocapitalize="off"
                                                    value={email}
                                                    required>
                                                </IonInput>
                                            </IonChip>
                                        </div>
                                        <IonLabel color="danger" text-wrap>{errors.email}</IonLabel>
                                        <div>
                                            <IonChip outline>
                                                <IonIcon icon={lock}></IonIcon>
                                                <IonInput type="password" placeholder="Password" text-start
                                                    onIonChange={handlePasswordTyping}
                                                    autocapitalize="off"
                                                    value={password}
                                                    required>
                                                </IonInput>
                                            </IonChip >
                                        </div>              
                                        <IonLabel color="danger" text-wrap>{errors.password}</IonLabel>                        
                                        {renderButtons()}
                                    </form>
                                    <IonRow class="ion-justify-content-center">
                                    <IonCol class="ion-justify-content-center ion-text-center" >
                                                    <IonTextarea disabled
      readonly rows="4" col="4" value="We store your email address in our database so we can show you just your customized preferences. We do not share it with any third-party entity.">
                                                    </IonTextarea>
                                       </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonToast
                isOpen={success}
                message="Login Success!"
                showCloseButton={true}
                duration={2000}
                onDidDismiss={() => setSuccess(false)}
            ></IonToast>
        </IonContent>
    )
}

export default withRouter(AuthCard);