import React from 'react';
import {withRouter} from 'react-router-dom';
import Menu from '../Routes/Routes';

const MainMenu = (props)=>
{
    return(
        <Menu>Menu</Menu>
    )
};

export default withRouter(MainMenu);