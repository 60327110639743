import React from "react";
import { withRouter } from 'react-router-dom';
import {
  IonList,
  IonItemGroup,
  IonLabel,
  IonItemDivider,
  IonCol,
  IonRow,
  IonGrid,
  IonItem,
  IonBadge,
  IonButton
} from "@ionic/react";
import FieldSet from './FieldSet';
import Field from './Field';

const FeedDetailsGroup = (props) => {

  const handleClick = (feedDetail) => {
    if (props.type === "Spuds") {
      window.open(feedDetail.rigMapURL, '_blank');
      return;
    }
    const queryParams = [];
    queryParams.push('uwi=' + encodeURIComponent(feedDetail["wellUWI"]));
    queryParams.push('name=' + encodeURIComponent(feedDetail["wellName"]));
    queryParams.push('no=' + encodeURIComponent(feedDetail["licenseNumber"]));
    queryParams.push('ldesc=' + encodeURIComponent(feedDetail["licenseeDesc"]));
    queryParams.push('ldt=' + encodeURIComponent(feedDetail["licenseDate"]));
    queryParams.push('sdt=' + encodeURIComponent(feedDetail["spudDate"]));
    queryParams.push('sbstc=' + encodeURIComponent(feedDetail["substance"]));
    queryParams.push('prf=' + encodeURIComponent(feedDetail["profile"]));
    queryParams.push('fld=' + encodeURIComponent(feedDetail["field"]));
    queryParams.push('frmt=' + encodeURIComponent(feedDetail["formation"]));
    queryParams.push('cntrtr=' + encodeURIComponent(feedDetail["contractorDesc"]));
    queryParams.push('rno=' + encodeURIComponent(feedDetail["rigNumber"]));
    const queryString = queryParams.join('&');

    let path = "/well/values";
    props.history.push({
      pathname: path,
      search: '?' + queryString
    });
  };

  const renderDetailGroup = (props) => {
    if (Array.isArray(props.feedDetails) && props.feedDetails.length !== 0) {
      return (
        <IonList>
          <IonItemGroup key={props.groupBy}>
            <IonItemDivider color="light" sticky>
              <IonItem color="light" lines="full">
                <IonBadge slot="start" color="dark">{props.feedDetails.length}{" "}</IonBadge>
                <IonLabel>
                  {props.groupBy}
                </IonLabel>
              </IonItem>
            </IonItemDivider>
            {props.feedDetails.map(feedDetail => {
              return (
                <IonItem key={feedDetail.licenseNumber + feedDetail.wellUWI} detail onClick={() => handleClick(feedDetail)} button>
                  <IonGrid style={{ maxWidth: "100%", width: "100%" }}>
                    <IonRow key={feedDetail.licenseNumber + feedDetail.wellUWI}>
                      <IonCol sizeMd="6" sizeXs="12" sizeSm="12" sizeLg="6" sizeXl="6">
                        {props.type === "Licenses"
                          ? <><IonLabel color="primary" style={{ paddingTop: "4px", paddingBottom: "4px" }}><h5>{feedDetail.wellUWI}</h5>
                              </IonLabel> 
                              <Field Value={feedDetail.wellName}></Field>
                            </>
                          :null
                        }
                        {props.type === "Spuds"
                          ? 
                            <>
                              <IonButton fill="clear" href={feedDetail.rigMapUrl}>
                                <IonLabel>{feedDetail.contractorDesc +" " +feedDetail.rigNumber}</IonLabel>
                              </IonButton>
                              <Field Value={feedDetail.rigCaption}></Field>
                            </>
                          :null
                        }
                        {props.type === "Licenses"
                          ? <FieldSet Caption={"Licensed on:"} Value={feedDetail.licenseDate}></FieldSet>
                          : null
                        }
                       
                        {props.type === "Spuds"
                          ? <FieldSet Caption={"Spudded on:"} Value={feedDetail.spudDate}></FieldSet>
                          : null
                        }
                      </IonCol>
                      <IonCol>
                        {props.type === "Licenses"
                          ? <FieldSet Caption={"Operator:"} Value={feedDetail.licenseeDesc}></FieldSet>
                          : null
                        }
                        {props.type === "Licenses"
                          ? <FieldSet Caption={"Number:"} Value={feedDetail.licenseNumber}></FieldSet>
                          : null
                        }
                        {props.type === "Spuds"
                          ? <FieldSet Caption={"Proj. Depth:"} Value={feedDetail.projDepth}></FieldSet>
                          : null
                        }
                        {props.type === "Spuds"
                          ? <FieldSet Caption={"Profile:"} Value={feedDetail.profile}></FieldSet>
                          : null
                        }
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              );
            })}
          </IonItemGroup>
        </IonList>
      );
    } else return <div />;
  };

  return renderDetailGroup(props);
};

export default withRouter(FeedDetailsGroup);
