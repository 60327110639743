import React from 'react';
import {IonContent} from '@ionic/react';
import ModalHeader from '../../Shared/ModalHeader';
import Preferences from '../../Preferences/Preferences';

const PreferencesModal = (props)=>{
    
    return(
        <>
        <ModalHeader title="Preferences" dismissModal={props.dismissModal}></ModalHeader>
        <IonContent>
          <Preferences></Preferences>
        </IonContent>
        </>
    )
};

export default PreferencesModal;