import React from 'react';
import AuthCard from '../../components/Shared/AuthCard'
import { IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent } from '@ionic/react';
//import Loading from '../../components/Shared/Loading'
//const AuthCard = React.lazy(()=>import('../../components/Shared/AuthCard'));

const SignupPage= ()=> {

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Create Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent justify-content-center align-items-center>
         {/* <Suspense fallback={<Loading/>}> */}
         <AuthCard type='Signup' isFromModal={false}></AuthCard>
         {/* </Suspense> */}
      </IonContent>
    </>
  );
}

export default SignupPage;
