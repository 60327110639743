import React from 'react';
import { withRouter } from 'react-router-dom';
import FeedActivity from './FeedActivity';
import FRigCountWeekly from "./FRigCountWeekly";

const Feed = (props) => {
    const render = (props) => {
        if ((props.feed.type === "Licenses") || 
                (props.feed.type === "Spuds") || (props.feed.type === "RigsCount")) {    
            return (
                <FeedActivity feed={props.feed} showModal={props.showModal}></FeedActivity>
            )
        }
        if (props.feed.type === "BHWeeklyRigCount") {
            return (
                <FRigCountWeekly feed={props.feed} showModal={props.showModal}></FRigCountWeekly>
            )
        }
    }

    return (
        render(props)
    )
};

export default withRouter(Feed);