import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { IonCard, IonCardContent, IonItem, IonLabel, IonButton, IonBadge, IonRow, IonCol } from '@ionic/react'
import Loading from '../../Shared/Loading';
import FeedActivityHeader from "./FeedActivityHeader";
import FeedTopsTable from './FeedTopsTable';
//const FeedTopsTable = React.lazy(() => import('./FeedTopsTable'));

const FeedActivity = (props) => {
  const handleFeedDetails = (e) => {
    if (props.feed.type !== "RigsCount") {
      const queryParams = [];
      queryParams.push('key=' + encodeURIComponent(props.feed["key"]));
      queryParams.push('from=' + encodeURIComponent(props.feed["dateKey"]));
      queryParams.push('to=' + encodeURIComponent(props.feed["dateKey"]));
      queryParams.push('title=' + encodeURIComponent(props.feed.provState + " Well " + props.feed.reportDesc));
      const queryString = queryParams.join('&');
      e.preventDefault();
      let path = "/activity/" + props.feed.provState + "-Well-" + props.feed.reportDesc

      props.history.push({
        pathname: path,
        search: '?' + queryString
      });
    }
    if (props.feed.type === "RigsCount") {
      window.open('https://rigs.geoactivity.com/tabs/rig-map', '_blank');
    }
  };

  const render = () => {
    if (props.feed) {
      return (
        <IonCard button>
          <IonCardContent button onClick={handleFeedDetails}>
            <FeedActivityHeader title={props.feed.provState} 
              dateDesc = {props.feed.dateDesc}
              imgPath = {props.feed.imgPath}
            />
            <IonItem lines="none" button detail={false} >
              <IonBadge slot="start" color="dark">{props.feed.count}{" "}</IonBadge>
              {/* <IonLabel> */}
                <h2>{props.feed.reportDesc}</h2>
              {/* </IonLabel> */}
            </IonItem>
            {(props.feed.type === "RigsCount")
              ?
              <>
                <IonRow>
                  <IonCol text-left>
                    <IonLabel text-left>
                      <b>{props.feed.topsTitle}</b>
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <Suspense fallback={<Loading />}>
                  <FeedTopsTable feed={props.feed}></FeedTopsTable>
                </Suspense>
              </>
              : <div />
            }

            {props.feed.type !== "RigsCount"
              ?
              // <IonItem lines="none">
              // <IonButton slot="end" text-right fill="outline" onClick={handleFeedDetails}>Details</IonButton>
              // </IonItem>
              null
              :
              <IonItem lines="none">
                <IonButton slot="end" text-right fill="outline" onClick={handleFeedDetails}>Rigs Map</IonButton>
              </IonItem>
            }
          </IonCardContent>
        </IonCard>
      )
    }
    else return <div />;
  }

  return (
    render()
  );
}

export default withRouter(FeedActivity);