import React, {Suspense} from 'react';
import { IonSplitPane, IonPage } from '@ionic/react';
//import {IonReactRouter as Router} from '@ionic/react-router'
import { Route, Switch } from 'react-router-dom';
import FeedsPage from '../pages/FeedsPage/FeedsPage';
import PreferencesPage from '../pages/PreferencesPage/PreferencesPage';
import Loading from '../components/Shared/Loading';
import MainMenu from '../components/MainMenu/MainMenu';
import FeedDetailsPage from '../pages/FeedDetailsPage/FeedDetailsPage';
import LoginPage from './Auth/LoginPage';
import SignupPage from './Auth/SignupPage';
import Firebase from '../config/FireBase';
import { ContextOne } from '../store/ContextOne';
import WellDetails from '../components/Feeds/FeedDetails/WellsDetails'
import * as ActionTypes from '../store/ActionsTypes';
import localforage from "localforage";
//const FeedsPage = React.lazy(()=>import('../pages/FeedsPage/FeedsPage'));
//const FeedDetailsPage = React.lazy(()=>import('../pages/FeedDetailsPage/FeedDetailsPage'));
//const PreferencesPage = React.lazy(()=>import('../pages/PreferencesPage/PreferencesPage'));
//const LoginPage = React.lazy(()=>import('./Auth/LoginPage'));
//const SignupPage = React.lazy(()=>import('./Auth/SignupPage'));
//const WellDetails = React.lazy(()=>import('../components/Feeds/FeedDetails/WellsDetails'));


const Layout = props => {

  let { dispatch, state } = React.useContext(ContextOne);

    //listener to Auth Changes and initialize settings state
  const unsubscribeFromAuth = React.useRef(null); 
  React.useEffect(() => {  
         unsubscribeFromAuth.current = Firebase.auth().onAuthStateChanged(user => {
        if (user) {
          if (state.userEmail !== user.email){
            dispatch({ type: ActionTypes.SET_AUTHENTICATION_STATUS, payload: { userId: user.uid, userEmail: user.email, isAuthenticated: true } });
          }
        } else {
          if (state.userEmail !== '')
            dispatch({ type: ActionTypes.SET_AUTHENTICATION_STATUS, payload: { userId: '', userEmail: '', isAuthenticated: false } });
        }
      })

    return () => unsubscribeFromAuth.current();
  });

  //get user report preference from DB if user login
  React.useEffect(() => {
    const getSubscribedStatus = () => {
      localforage.getItem("isSubscribed").then(function(value) {
        dispatch({
          type: ActionTypes.SET_SUBSCRIBED_STATUS,
          payload: { isSubscribed: value }
        });
      });
    };
    if (state.userId) {
      var userRptPrefsRef = Firebase.database().ref('preferences/' + state.userId + '/rptPrefs');
      userRptPrefsRef.on('value', function (snapshot) {
        const ssValue = snapshot.val();
        if (ssValue) {
          if (ssValue.length > 0) {
            dispatch({ type: ActionTypes.GET_REPORT_PREFERENCES, payload: ssValue });
          }
        }
      });
      getSubscribedStatus();
    }
  }, [state.userId, dispatch]);

  //save user preferencess on db when it changes
  React.useEffect(() => {
    if (state.reportPreferences) {
      if (state.reportPreferences.length > 0) {
        if (state.userId) {
          var db = Firebase.database();
          db.ref('preferences/' + state.userId).set({ id: state.userId, rptPrefs: state.reportPreferences });
        }
      }
    }
  }, [state.reportPreferences,state.userId]);

  

  const routes = (
     
    <Switch>
  {/* <IonRouterOutlet> */}
      <Route path="/well/:details" exact component = {WellDetails} />
      {/* <Route exact path="/well/:details" render={() =><WellDetails/>} /> */}
      <Route path="/activity/:feedType" exact component = {FeedDetailsPage} />
      {/* <Route exact path="/activity/:feedType" render={() =><FeedDetailsPage/>} /> */}
      <Route path="/preferences" exact component = {PreferencesPage} />
      {/* <Route path="/preferences" exact render={() => <PreferencesPage/>} /> */}
      <Route exact path="/login" component = {LoginPage} />
      {/* <Route exact path="/login" render={() =><LoginPage/>} /> */}
      <Route exact path="/signup" component = {SignupPage} />
      {/* <Route exact path="/signup" render={() =><SignupPage/>} /> */}
      <Route exact path="/" component = {FeedsPage} />
      {/* <Route exact path="/" render={() =><FeedsPage/>} /> */}
      {/* </IonRouterOutlet> */}
    </Switch>

  );

  return (
    //<IonApp>
      <IonSplitPane contentId="main">
          <MainMenu />
          <IonPage id="main">
            <Suspense fallback={<Loading/>} >
              {routes}
            </Suspense>
          </IonPage>
     </IonSplitPane>
    //</IonApp>
  )
}

export default Layout;
