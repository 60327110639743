import React, { useEffect } from "react";

import {
  IonRadioGroup,
  // IonListHeader,
  IonRadio,
  IonContent,
  IonList,
  IonItem,
  IonLabel
} from "@ionic/react";
import ModalHeader from "../Shared/ModalHeader";

const DateSelect = (props) => {
  const initialStatus = {
    today: false,
    yesterday: false,
    last_7_days: false,
    last_15_days: false
  }
  initialStatus[props.currentDateRange] = true
  const [checkedDateRange, setCheckedDateRange] = React.useState();
  const [status, setStatus] = React.useState(initialStatus);
  useEffect(() => {
    setCheckedDateRange(props.currentDateRange)
  }, [props.currentDateRange])

  useEffect(() => {
    if (checkedDateRange && status[checkedDateRange] !== true) {
      let newStatus = { ...status };
      newStatus[checkedDateRange] = true;
      setStatus(newStatus);
    }
  }, [status, checkedDateRange]);

  const handleOnSelect = (e) => {
    let newStatus = { ...status };
    newStatus[e.detail.value] = true;
    setStatus(newStatus);
    if (e.detail.checked)
      setCheckedDateRange(e.detail.value)
  }

  return (
    <>
      <ModalHeader title="Date Range" dismissModal={() => props.dismissModal(checkedDateRange)} />
      <IonContent>
        {(status) && (
          <IonList lines="none">
            <IonRadioGroup >
              {/* <IonListHeader>Select Dates Range</IonListHeader> */}
              {/* <IonItem>
              <IonRadio slot="start" value="today" checked={status.today}  onIonSelect={handleOnSelect} />
              <IonLabel>Today</IonLabel>
            </IonItem> */}
              <IonItem>
                <IonRadio slot="start" value="yesterday" checked={status.yesterday} onIonSelect={(e) => { handleOnSelect(e) }} />
                <IonLabel>Yesterday</IonLabel>
              </IonItem>
              <IonItem>
                <IonRadio slot="start" value="last_7_days" checked={status.last_7_days} onIonSelect={(e) => { handleOnSelect(e); }} />
                <IonLabel>Last 7 days</IonLabel>
              </IonItem>
              <IonItem>
                <IonRadio slot="start" value="last_15_days" onIonSelect={(e) => { handleOnSelect(e); }} />
                <IonLabel>Last 15 days</IonLabel>
              </IonItem>
            </IonRadioGroup>
          </IonList>
        )}
      </IonContent>
    </>
  );
};

export default DateSelect;
