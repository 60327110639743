import AxiosFeeds from '../config/AxiosFeeds';


const userUpdateInGeoFeeds = async (payload) => {
    let state = payload.state;
    if (state) {
        if (state.userId !== '') {
            const p = {
                'guid': state.userId,
                "email": state.userEmail,
                "reports": state.reportPreferences,
                "isSubscribed": state.isSubscribed
            }
            try {
                return await AxiosFeeds.post('/preferences/PostPrefs', p)
            } catch (error) {
                console.error(error)
            }
        }
    }
}

export const updateUserInGeoFeeds = async (payload) => {
    await userUpdateInGeoFeeds(payload)
    //.then(console.log("user data updated"));
}
