import React from 'react';
import { IonFabButton, IonIcon, IonFabList, IonFab } from '@ionic/react';
import { share, mail, logoFacebook } from "ionicons/icons";

const ShareButtons = (props) => {
  const openSocial = (socialNetwork) => {
    if (socialNetwork === 'Facebook') {
      window.open('https://www.facebook.com/sharer/sharer.php?u='+ props.fbURL, 'facebook-share-dialog', "width=626, height=436")
    }
    else
      if (socialNetwork === 'Email') {
        window.location.href = 'mailto:?subject=' + props.subject + '&body=' + props.bodyText;
      }
  }

  return (
      <IonFab slot="fixed" vertical="bottom" horizontal={props.hpos}>
        <IonFabButton>
          <IonIcon icon={share}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton color="facebook" onClick={() => { openSocial('Facebook'); return false; }}>
            <IonIcon icon={logoFacebook}></IonIcon>
          </IonFabButton>
          <IonFabButton onClick={() => openSocial('Email')}>
            <IonIcon icon={mail}></IonIcon>
          </IonFabButton>
        </IonFabList>
      </IonFab> 
  )
}

export default ShareButtons