import React from 'react'
import { IonLabel} from '@ionic/react'

const FieldSet = (props) => {

    const render = () => {
        if (props.Value) {
            return (
                <div style={{ display: "flex", paddingLeft: "5px", paddingTop: "4px", paddingBottom: "4px" }}>
                    <IonLabel>
                        <p>{props.Caption}</p>
                    </IonLabel>
                    <IonLabel slot="start" color="dark" style={{ paddingLeft: "5px" }}>
                        <p color="primary-tint">{props.Value}</p>
                    </IonLabel>
                </div>
            )
        }
        else { return <div /> }
    }

    return (render())
}

export default FieldSet;