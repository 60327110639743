import React from "react";
import { IonRow,
  IonGrid,
  IonLabel,
  IonCol,
  IonImg
} from "@ionic/react";

const FeedActivityHeader = props => {
  return (
    <IonGrid>
      <IonRow class="ion-align-items-center">
        <IonCol>
          <IonRow>
            <IonCol>
              <IonLabel>
                <h1>{props.title}</h1>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {/* <IonLabel > */}
              <p>{props.dateDesc}</p>
              {/* </IonLabel> */}
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol class="
          ion-justify-content-end
          ion-text-end
          ion-no-padding" 
          style={{ minWidth: "80px", maxWidth: "80px" }}
        >
          <IonImg class="ion-no-padding" src={props.imgPath} alt={props.title} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default FeedActivityHeader;
