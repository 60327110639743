import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { IonContent, IonList } from '@ionic/react';
import FeedDetailsGroup from './FeedDetailsGroup';
import AxiosFeeds from '../../../config/AxiosFeeds';
import HelmetSEO from '../../Shared/Helmet';
import ShareButtons from '../../Shared/ShareButtons';
import PageBackHeader from '../../Shared/PageBackHeader';
import * as formatDate from '../../../services/dateHelper';
//import DateSelect from '../../Shared/DateSelect';
import FeedDetailDateSelector from './FeedDetailDateSelector';
//import AdSense from 'react-adsense';
//import { ContextOne } from '../../../store/ContextOne';
//import SubscribeCard from '../Feed/SubscribeCard';
//import {useParams} from 'react-router-dom'
import Loading from '../../Shared/Loading';
//import Loading from '../../Shared/Loading';

const FeedDetails = (props) => {
  //let { state } = React.useContext(ContextOne);

  const [subject, setSubject] = React.useState();
  const [bodyText, setBodyText] = React.useState();
  const [seoDescription, setSeoDescription] = React.useState();
  const [headerText, setHeaderText] = React.useState("");
  const [dates, setDates] = React.useState("");
  const [groupByValues, setGroupByValues] = useState({});
  const [type, setType] = useState("Licenses");
  const [groupBy, setGroupBy] = useState({});
  const [reportData, setReportData] = useState({});
  const [filteredReportData, setFilteredReportData] = useState({});
  const [showDates, setShowDates] = useState(false);
  const [reportKey, setReportKey] = useState(undefined);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [currentDateRange, setCurrentDateRange] = useState("yesterday");

  let feedDetails = [];
  let reportGrouped = [];

  const shareURL = encodeURIComponent('https://feeds.geoactivity.com' + props.location.pathname + props.location.search);

  

  React.useEffect(() => {
    let params = {};
    const query = new URLSearchParams(props.location.search);
    
    for (let param of query.entries()) {
      params[param[0]] = param[1];
    }
    
    let desc = params.title;
    setSubject("I wanted you to see this site with the latest " + desc);
    let bodyText = "Hi,%0D%0A%0D%0A"
    bodyText += "Please look to this website with the latest " + desc + "%0D%0A"
    bodyText += "%0D%0A"
    bodyText += "Check it out => " + shareURL + "%0D%0A%0D%0A"
    bodyText += "Thank you,%0D%0A";
    setBodyText(bodyText);
    setHeaderText(params.title);
    setSeoDescription(params.title + " for " + formatDate.getDatePretty(params.from));
    setDates(params.from + " - " + params.to)
    setReportKey(params.key)
    
    if (params.days){
      const dateRange = formatDate.getDateRangeValue(params.days);
      setFromDate(dateRange.startDate);
      setToDate(dateRange.endDate);
      setDates(dateRange.startDate + " - " + dateRange.endDate)
      setCurrentDateRange(params.days)
    }
    else{
      setFromDate(params.from);
      setToDate(params.to);
    }
    bodyText += "Check it out => " + shareURL + "%0D%0A%0D%0A"
  }, [props.location.search, shareURL]);

  // React.useEffect(() => {
  //     console.log("reportData changed Feeds Details")
  // }, [reportData]);
  
  React.useEffect(() => {
    const setFeedDetails = async () => {
      if (reportKey && fromDate && toDate) {
        feedDetails = await getFeedDetails();
        if (feedDetails && feedDetails.data) {
          setType(feedDetails.data.type);
          setGroupBy(feedDetails.data.groupBy);
          setGroupByValues(feedDetails.data.groupByValues);
          setReportData(feedDetails.data.data);
          setFilteredReportData(feedDetails.data.data);
        }
      }
    }
    if (fromDate) {
      setFeedDetails();
    }
  }, [fromDate]);


  const getFeedDetails = async (payload) => {
    const p = {
      params: {
        key: reportKey,
        fromDate: fromDate,
        toDate: toDate,
      }
    }

    try {
      return await AxiosFeeds.get('/feeddetails?', p)
    } catch (error) {
      console.error(error)
    }
  }

  // const search = (searchText, array) => {
  //     let result = [];
  //     array.forEach(element => {
  //         let isMatch = Object.keys(element).filter((key) => {
  //             if (typeof element[key] === "string") {
  //                 if (element[key].toLowerCase().includes(searchText.toLowerCase())) {
  //                     return true;
  //                 }
  //                 else return false;
  //             }
  //             else return false;
  //         });
  //         if (isMatch.length > 0) {
  //             result.push(element);
  //         }
  //     });
  //     if (result.length > 0 )
  //         return result;
  //     else
  //        return []
  // }

  // const handleSearch = (searchValue) => {
  //     if (searchValue.detail.value) {
  //         const result = search(searchValue.detail.value, reportData);
  //           setFilteredReportData(result);
  //     }
  //     else {
  //         setFilteredReportData(reportData);
  //     }
  // }

  const getReportGroup = (groupByValue) => {
    return filteredReportData.filter(fd => fd[groupBy] === groupByValue);
  }

  // const dismisModal = () => {
  //     //console.log("dismissing", showDates);
  //     if (showDates)
  //         setShowDates(false);
  // }

  const setDateSelected = (value) => {
    if (currentDateRange !== value) {
      setCurrentDateRange(value);
      const dateRange = formatDate.getDateRangeValue(value);
      setFromDate(dateRange.startDate);
      setToDate(dateRange.endDate);
      setDates(dateRange.startDate + " - " + dateRange.endDate)
    }
    if (showDates)
      setShowDates(false);
  }

  const renderDetails = () => {
    let linesCount = 0;
    if (Array.isArray(filteredReportData) && filteredReportData.length !== 0) {
      return (
        <>
          <HelmetSEO
            title={headerText}
            description={seoDescription}
          />
          <IonContent>
            <FeedDetailDateSelector
              onDateSelectClick={() => { setShowDates(true) }}
              count={reportData.length}
              dates={dates}
              showDates={showDates}
              currentDateRange={currentDateRange}
              setDateSelected={setDateSelected}
              dismissModal={setDateSelected}
            />

            {filteredReportData.length !== 0 &&
              Array.isArray(filteredReportData) && (
                <IonList>
                  {groupByValues.map(groupByValue => {
                    linesCount++;
                    reportGrouped = getReportGroup(groupByValue);
                    return (
                      <div key={linesCount}>
                        <FeedDetailsGroup
                          key={groupByValue}
                          type={type}
                          groupBy={groupByValue}
                          feedDetails={
                            reportGrouped
                          }
                        />
                        {/* {!state.isSubscribed && linesCount === 1 ? (
                                  <SubscribeCard />
                            ) : null} */}
                        {/* {linesCount % 2 === 0 ? (
                               
                            ) : null} */}
                      </div>
                    );
                  })}
                </IonList>
              )}
          </IonContent>
          <ShareButtons
            subject={subject}
            bodyText={bodyText}
            hpos={"end"}
            fbURL={shareURL}
          />
        </>
      );
    }
    else return (<Loading />);
  };

  return (
    <>
      <PageBackHeader headerText={headerText}></PageBackHeader>
      {renderDetails(props)}
    </>
  )
};

export default withRouter(FeedDetails);