import React from "react";
import {
  IonCard,
  IonCardContent,
  IonLabel,
  IonItem,
  IonButton,
  IonBadge,
  IonRow,
  IonCol
} from "@ionic/react";
import FeedActivityHeader from "./FeedActivityHeader";     

const FRigCountWeekly = (props) =>{
    const handleFeedDetails = () => {
    }

    return (
        <IonCard button>
          <IonCardContent button onClick={handleFeedDetails}>
          <FeedActivityHeader title={props.feed.provState} 
              dateDesc = {props.feed.dateDesc}
              imgPath = {props.feed.imgPath}
            />
            <IonItem lines="none" button detail={false} >
              <IonBadge slot="start" color="dark">{props.feed.count}{" "}</IonBadge>
              <IonLabel>
                {props.feed.reportDesc}
              </IonLabel>
            </IonItem>
            {(props.feed.type === "RigsCount")
              ?
              <>
                <IonRow>
                  <IonCol text-left>
                    <IonLabel text-left>
                      <b>{props.feed.topsTitle}</b>
                    </IonLabel>
                  </IonCol>
                </IonRow>

                {/* <Suspense fallback={<Loading />}>
                  <FeedTopsTable feed={props.feed}></FeedTopsTable>
                </Suspense> */}
              </>
              : <div />
            }

            {props.feed.type !== "RigsCount"
              ?
              // <IonItem lines="none">
              // <IonButton slot="end" text-right fill="outline" onClick={handleFeedDetails}>Details</IonButton>
              // </IonItem>
              null
              :
              <IonItem lines="none">
                <IonButton slot="end" text-right fill="outline" onClick={handleFeedDetails}>Rigs Map</IonButton>
              </IonItem>
            }
          </IonCardContent>
        </IonCard>
      )
}
export default FRigCountWeekly