import React from 'react';
import { IonHeader, IonButtons, IonToolbar, IonTitle, IonButton } from '@ionic/react';

const ModalHeader = (props) =>{
    return(
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {props.title}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton  strong onClick={props.dismissModal}>Done</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
    )
}

export default ModalHeader;