import React from 'react';
import { IonContent, IonGrid, IonRow, IonCol, IonList, IonItemGroup, IonModal } from '@ionic/react';
import LoginModal from '../../pages/Auth/LoginModal';
import ReportGroup from './ReportGroup';
import { ContextOne } from '../../store/ContextOne';
import * as ActionTypes from '../../store/ActionsTypes'
//import EmailConsent from '../Shared/EmailConsent';


const Preferences = (props) => {

    const [showLoginModal, setShowLoginModal] = React.useState(false);

    let { dispatch, state } = React.useContext(ContextOne);

    React.useEffect(()=>{
        dispatch({type: ActionTypes.SET_PREF_REPORTS})
    },[dispatch])

    let reportGrouped = [];

    const getReportsByprovState = (provState) => {
        return state.reports.filter(r => r.provState === provState);
    }
    
    const renderLoginModal = () => {
        return (
            // <IonContent>
                <IonModal isOpen={showLoginModal} ionModalDidDismiss={() => setShowLoginModal(false)}>
                    <LoginModal isOpen={showLoginModal}
                        dismissModal={() => setShowLoginModal(false)}
                    />
                </IonModal>
            // </IonContent>
        )
    }

    const render = () => {
      if (state.provinces.length > 0) {
        return (
          <IonContent>
            <IonGrid style={{ height: "100%" }}>
              <IonRow>
                <IonCol>
                  <IonList padding>
                    {/* <EmailConsent showModal={() => setShowLoginModal(true)}/> */}
                    {state.provinces.map(item => {
                      reportGrouped = getReportsByprovState(item.provState);
                      return (
                        <React.Fragment key={item.key}>
                          <IonItemGroup>
                            <ReportGroup
                              key={item.dateDesc}
                              reports={reportGrouped}
                              showModal={() => setShowLoginModal(true)}
                            />
                          </IonItemGroup>
                        </React.Fragment>
                      );
                    })}
                  </IonList>
                  {renderLoginModal()}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        );
        } else {
             return null
        };
    };

    return (
        render()
    )
}

export default Preferences;