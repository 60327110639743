import React from 'react';
import { IonModal, IonItem, IonBadge, IonChip, IonLabel, IonIcon } from '@ionic/react';
import DateSelect from '../../Shared/DateSelect';
import { create } from 'ionicons/icons'


const FeedDetailDateSelector = React.memo((props) => {

  const renderDatesModal = () => {
    return (
      <>
        <IonModal isOpen={props.showDates} backdropDismiss={false} >
          <DateSelect dismissModal={props.setDateSelected}
                        currentDateRange={props.currentDateRange} />
        </IonModal>
      </>
    )
  }

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "0px" }}>
      <IonItem lines="none" tappable onClick={props.onDateSelectClick}>
        <IonBadge color="primary">{props.count} </IonBadge>
        <IonChip slot="end" color="primary">
          <IonLabel> Date: {props.dates}</IonLabel>
          <IonIcon icon={create} />
        </IonChip>
      </IonItem>
      {renderDatesModal()}
    </div>
  );
})

export default FeedDetailDateSelector;