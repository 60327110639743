import React from 'react';
import { IonList, IonContent } from '@ionic/react';
import PageBackHeader from '../../Shared/PageBackHeader';
import WellDetailsRow from './WellsDetailsRow';
import {withRouter} from 'react-router-dom';
//import HelmetSEO from '../../Shared/Helmet'
//import ShareButtons from '../../Shared/ShareButtons'

const WellDetails = (props) => {
    
    const [data, setData] = React.useState();
   // const [description, setDescription] = React.useState();
    //const [subject, setSubject] = React.useState();
    //const [bodyText, setBodyText] = React.useState();
    
    //let shareURL = encodeURIComponent('https://feeds.geoactivity.com'+props.location.pathname+props.location.search);

    React.useEffect(()=>{
        let params = {};
        const query = new URLSearchParams(props.location.search);
        for (let param of query.entries()) {
            params[param[0]]=param[1];
        }
        setData(params);
        //const desc = "Well UWI: " + params.uwi + " - License number: "+ params.no + " - Licensed on: " + params.ldt;
         //setDescription(desc);
         //setSubject("I wanted you to see this well "+ desc);
         //let bodyText ="Hi,%0D%0A%0D%0A"
        //  bodyText +="Please look to this well information " +desc+ "%0D%0A"
        //  bodyText +="Check it out => "+shareURL+"%0D%0A%0D%0A"
        //  bodyText +="Thank you,%0D%0A";
         //setBodyText(bodyText);
    },[props.location.search]);

    //const data = props.location.data;

    const render = () => {
        if (data) {
            return (
                <>
                    {/* <HelmetSEO title="Wells | GeoActivity Feeds" description={description}></HelmetSEO> */}
                    <PageBackHeader headerText={data.uwi}></PageBackHeader>
                    <IonContent>
                        <IonList>
                            <WellDetailsRow caption="UWI" value={data.uwi}></WellDetailsRow>
                            <WellDetailsRow caption="Name" value={data.name}></WellDetailsRow>
                            <WellDetailsRow caption="Number" value={data.no}></WellDetailsRow>
                            <WellDetailsRow caption="Operator" value={data.ldesc}></WellDetailsRow>
                            <WellDetailsRow caption="Licensed on" value={data.ldt}></WellDetailsRow>
                            <WellDetailsRow caption="Spudded on" value={data.sdt}></WellDetailsRow>
                            <WellDetailsRow caption="Substance" value={data.sbstc}></WellDetailsRow>
                            <WellDetailsRow caption="Profile" value={data.pf}></WellDetailsRow>
                            <WellDetailsRow caption="Field" value={data.fld}></WellDetailsRow>
                            <WellDetailsRow caption="Formation" value={data.frmt}></WellDetailsRow>
                            <WellDetailsRow caption="Contractor" value={data.cntrtr}></WellDetailsRow>
                            <WellDetailsRow caption="Rig Number" value={data.rno}></WellDetailsRow>
                        </IonList>
                    </IonContent>
                    {/* <ShareButtons subject={subject} bodyText={bodyText} hpos={"center"}
                        fbURL={shareURL}>
                    </ShareButtons> */}
                </>
            )
        }
        else return <div />;
    }

    return(
        render()
    )
}

export default withRouter(WellDetails);
