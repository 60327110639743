import React from 'react';
import ToolBarMenu from '../../components/MenuItems/ToolBarMenu';
import Preferences from '../../components/Preferences/Preferences';
//const Preferences = React.lazy(()=>import('../../components/Preferences/Preferences'));

const PreferencesPage = (props)=>{
    return(
        <>
        <ToolBarMenu Title ="Preferences"/>
         {/* <Suspense fallback={<Loading/>}> */}
        <Preferences/>
         {/* </Suspense> */}
        </>
    )
};

export default PreferencesPage;