import * as React from "react";
import Firebase from '../config/FireBase';
import * as actionTypes from '../store/ActionsTypes';
import * as RequestSrv from '../services/requestSrv';


let ContextOne = React.createContext();

let initialState = {
  loaded: false,
  dateList:[],
  feedListOriginal:[],
  feedList:[],
  userId: '',
  userEmail: '',
  isAuthenticated: false,
  feedsEmail: '',
  isSubscribed: false,
  reports: [],
  reportPreferences: [],
  provinces: []
};

let reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTHENTICATION_STATUS: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userId: action.payload.userId,
        userEmail: action.payload.userEmail
      };
    }

    case actionTypes.LOGOUT: {
      handleLogout();
      return {
        ...state,
        isAuthenticated: false,
        userId: '',
        userEmail: ''
      };
    }

    case actionTypes.SET_PREF_REPORTS:{
       return initReports(state);
    }
     
    
    case actionTypes.SET_SUBSCRIBED_STATUS: {
      let rptPrefs = [];
      rptPrefs = initReportPrefs(state, rptPrefs);
      let newState = {
        ...state,
        reportPreferences: rptPrefs,
        isSubscribed: action.payload.isSubscribed
      };
      RequestSrv.updateUserInGeoFeeds({"state": newState});
      return newState;
    }

    case actionTypes.REPORT_STATUS_CHANGED: {
        const rptPrefs = updateReportPrefs(state, action.payload);
        let newState = {
          ...state,
          reportPreferences: rptPrefs
        };
        RequestSrv.updateUserInGeoFeeds({ state: newState });
        return newState;
    }

    case actionTypes.GET_REPORT_PREFERENCES: {
      return {
        ...state,
        reportPreferences: action.payload,
        feedList: state.feedListOriginal.filter(r => action.payload.includes(r.key))
      };
    }

    case actionTypes.SET_FEEDS:{
      return {
        ...state,
           dateList: action.payload.dates,
           feedListOriginal: action.payload.reports,
           feedList: action.payload.reports,
           loaded: true
      }
    }
    default:
      return state;
  }
};

const handleLogout = (event) => {
  Firebase.auth().signOut()
    .catch((error) => {
      console.log(error);
    });
}

const updateReportPrefs = (state, payload) => {
  let result = [];
  let rptPrefs = [];
  //its is the first time that user add or del report, it is initialized with a copy of all reports
  rptPrefs = initReportPrefs(state, rptPrefs);

  if (payload.action === "ADD") {
    if (!rptPrefs.includes(payload.rptId)) {
      rptPrefs.push(payload.rptId);
      return rptPrefs;
    }
  }

  if (payload.action === "DEL") {
    result = rptPrefs.filter((el) => el !== payload.rptId);
    return result;
  }
}

const initReports = (state) => {
  const reports =[
    { key: '1', reportDesc: "Well Licenses", provState: "Alberta" },
    { key: '2', reportDesc: " Spuds", provState: "Alberta" },
    { key: '3', reportDesc: "Well Licenses", provState: "Saskatchewan" },
    { key: '4', reportDesc: "Spuds", provState: "Saskatchewan" },
    { key: '5', reportDesc: "Well Licenses", provState: "British Columbia" },
    { key: '6', reportDesc: "Spuds", provState: "British Columbia" },
    { key: '7', reportDesc: "Well Licenses", provState: "Manitoba" },
    { key: '8', reportDesc: "Spuds", provState: "Manitoba" },
    { key: '11', reportDesc: "Drilling Rigs", provState: "Rig Count" },
    // { key: '8', reportDesc: "Well Spuds", provState: "North Dakota" }
  ]
  
  return {
    ...state,
    reports:reports, 
    provinces: [{ key: 1, provState: "Alberta" },
    { key: 2, provState: "Saskatchewan" },
    { key: 3, provState: "British Columbia" },
    { key: 4, provState: "Manitoba" },
    //{ key: 5, provState: "North Dakota" },
    { key: 6, provState: "Rig Count" }
    ]
  }
}

function initReportPrefs(state, rptPrefs) {
  if (state.reportPreferences && state.reportPreferences.length === 0) {
    for (let i = 0; i < state.reports.length; i++) {
      rptPrefs.push(state.reports[i].key);
    }
  }
  else
    rptPrefs = state.reportPreferences.slice();
  return rptPrefs;
}

function ContextOneProvider(props) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };
  // [B]
  return (
    <ContextOne.Provider value={value}>{props.children}</ContextOne.Provider>
  );
}

let ContextOneConsumer = ContextOne.Consumer;

// [C]
export { ContextOne, ContextOneProvider, ContextOneConsumer }

