import React from 'react'
import { IonHeader, IonToolbar, IonButtons, IonButton } from '@ionic/react'


const appName = (props) => {
    return (
        <IonHeader no-border >
            <IonToolbar  >
                <IonButtons slot="start">
                    <IonButton fill="clear" href="https://www.geoactivity.com/">
                        GeoActivity Feeds
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}
export default appName;