import React from 'react';
import { IonItem, IonLabel, IonToggle } from '@ionic/react';
import { ContextOne } from '../../store/ContextOne';
import * as ActionTypes from '../../store/ActionsTypes';

const Report = (props) => {

    const { state, dispatch } = React.useContext(ContextOne);

    const IsChecked = (reportKey) => {
         if (state.reportPreferences) {
            if (state.reportPreferences.length > 0) {
                if (state.reportPreferences.includes(reportKey)) {
                    return true;
                }
                else{
                    return false;
                }
            }
            return true;
        }
    }

    const handlePreferenceChange = (rptId, actionType) => {
        if (state.isAuthenticated) {
            const actionDesc = actionType === true ? "ADD" : "DEL";
            if (state.reportPreferences) {
                dispatch({ type: ActionTypes.REPORT_STATUS_CHANGED, payload: { action: actionDesc, rptId: rptId } })
            }
        }
        else
        {
           props.showModal(true);
        }
    }


    const render = ()=>{
        if(props.report) {
            return(
            <IonItem key={props.report.key}>
               <IonLabel key={props.report.reportDesc}>{props.report.provState + " " +props.report.reportDesc}</IonLabel>
               <IonToggle slot='start'
                   checked={IsChecked(props.report.key)}
                   onIonChange={(event) => handlePreferenceChange(props.report.key, event.detail.checked)}
               ></IonToggle>
            </IonItem>
            )
        }
        else return <div />
    }

    return (
        render()
    );

}

export default Report;