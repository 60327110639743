import React from 'react';
import {IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle} from '@ionic/react';

const toolBarMenu = (props) => {
    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonTitle>{props.Title}</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
};
 export default toolBarMenu;