import React from 'react'
import { IonLabel} from '@ionic/react'

const Field = (props) => {

   const render = () => {
      if (props.Value) {
         return (
            <div style={{ display: "flex", paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px" }}>
               <IonLabel slot="start" color="dark" style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                  <p color="primary-tint">{props.Value}</p>
               </IonLabel>
            </div>
         )
      }
      else { return <div /> }
   }

   return (render())
}

export default Field;