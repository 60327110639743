import React from 'react';
import Report from './Report';


const ReportGroup = props => {

     const render = ()=> {
        if (props.reports) {
            return (
                props.reports.map((report) => {
                    return (
                        <Report key={report.key} report={report} showModal={props.showModal}>
                        </Report>
                    )
                }
                )
            )
        }
        else 
            return null;
    }
    return (      
        render()
    )
};

export default ReportGroup;