import React from 'react';
//import {BrowserRouter as Router} from 'react-router-dom'
import {IonReactRouter as Router} from '@ionic/react-router'
import {IonApp} from '@ionic/react'
import Layout from './pages/Layout';
import './theme/variables.css';
import { ContextOneProvider } from './store/ContextOne';
import { LastLocationProvider } from 'react-router-last-location';
import { HelmetProvider } from 'react-helmet-async';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

const app = props => {
  return (
  <IonApp>
    <Router>
      <LastLocationProvider>
      {/* <React.StrictMode> */}
        <ContextOneProvider>
        <HelmetProvider>
          <Layout />
          </HelmetProvider>
        </ContextOneProvider>
      {/* </React.StrictMode> */}
      </LastLocationProvider>
    </Router>
  </IonApp>
  )
};

export default app;
