import React from 'react';
import { IonRow, IonCol } from '@ionic/react'
import Feed from './Feed';
//const Feed = React.lazy(()=>import('./Feed'));
//import AdCard from '../Feed/AdCard';
//import  FRigCountWeekly from "./FRigCountWeekly";
import { ContextOne } from '../../../store/ContextOne';
//import SubscribeCard from '../Feed/SubscribeCard';

const FeedGroup = props => {
    let { state } = React.useContext(ContextOne);
    const feeds = state.feedList.filter(f => f.dateKey === props.dateKey);
   
    return (
      <>
        <IonRow>
          {feeds.map(feed => {
            return (
              <IonCol key={feed.key} sizeXs="12" sizeMd="6" sizeXl="4">
                {/* <Suspense fallback={<Loading/>}> */}
                <Feed
                  key={feed.key}
                  feed={feed}
                  showModal={props.showModal}
                />
                {/* </Suspense>   */}
              </IonCol>
            );
          })}
        </IonRow>
        {/* {!state.isSubscribed && props.groupCount === 1 ? (
          <SubscribeCard />
        ) : null} */}
        {/* {props.groupCount >= 2 ? (
          <IonRow>
            <IonCol>
              <AdCard />
            </IonCol>
          </IonRow>
        ) : null} */}
      </>
    );
};

export default FeedGroup;