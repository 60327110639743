export const SET_AUTHENTICATION_STATUS ='SET_AUTHENTICATED_STATUS';
export const LOGOUT ='LOGOUT';

export const SET_PREF_REPORTS = "SET_PREF_REPORTS";
export const SET_SUBSCRIBED_STATUS ="SET_SUBSCRIBED_STATUS";
export const GET_SUBSCRIBED_STATUS ="SET_SUBSCRIBED_STATUS";
export const REPORT_STATUS_CHANGED = "REPORT_STATUS_CHANGED";
export const GET_REPORT_PREFERENCES = "GET_REPORT_PREFERENCES";
export const UPDATE_APP_REPORTS = "UPDATE_APP_REPORTS";

export const SET_FEEDS = "SET_FEEDS";

