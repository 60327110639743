import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config ={
  // Initialize Firebase
    apiKey: "AIzaSyBJUaVKLGbTXEHFBzzsmAHiwkcVrOn2lcw",
    authDomain: "geoactivity-feeds.firebaseapp.com",
    databaseURL: "https://geoactivity-feeds.firebaseio.com",
    projectId: "geoactivity-feeds",
    storageBucket: "geoactivity-feeds.appspot.com",
    messagingSenderId: "1078259963028"
};
const FireBase = firebase.initializeApp(config);
export default FireBase;