import React from 'react';
//import ToolBarMenu from '../../components/MenuItems/ToolBarMenu';
import Feeds from '../../components/Feeds/Feed/Feeds';


const FeedsPage = (props)=>{
           
    return(     
          <Feeds></Feeds>
    ) 
};

export default FeedsPage;