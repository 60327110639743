import React, { useState, useEffect, Suspense } from 'react';
import {
    IonContent, IonList, IonItemDivider, IonIcon,
    IonText, IonModal, IonHeader, IonToolbar, IonButtons,
    IonMenuButton, IonTitle, IonButton
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
//import ShareButtons from '../../Shared/ShareButtons';
import FeedGroup from './FeedGroup';
import { ContextOne } from '../../../store/ContextOne';
import AxiosFeeds from '../../../config/AxiosFeeds';
import Loading from '../../Shared/Loading';
import * as ActionTypes from '../../../store/ActionsTypes';
import PreferenceModal from './PreferenceModal';
//const PreferenceModal = React.lazy(() => import('./PreferenceModal'));
//const FeedGroup = React.lazy(() => import('./FeedGroup'));
import { more } from 'ionicons/icons'



const Feeds = (props) => {

    let { dispatch, state } = React.useContext(ContextOne);

    const [showPrefsModal, setShowPrefsModal] = useState(false);

    useEffect(() => {
        const setFeeds = async (payload) => {
            if (state && !state.loaded) {
                let feeds = await getFeeds(payload);
                if (feeds && feeds.data) {
                    dispatch({
                        type: ActionTypes.SET_FEEDS,
                        payload: {
                            dates: feeds.data.dates,
                            reports: feeds.data.reports
                        }
                    });
                }
            }
        }
        let feedsPayload = {
            days: 6,
            reports: ""
        };
        setFeeds(feedsPayload);
    }, [state.loaded, dispatch, state]);

    useEffect(() => {
    }, [state.feedList]);

    const getFeeds = async (payload) => {
        const p = {
            params: {
                days: payload.days,
                reports: payload.reports
            }
        }
        try {
            return await AxiosFeeds.get('/feeds?', p)
        } catch (error) {
            console.error(error)
        }
    }



    const handleModal = () => {
        setShowPrefsModal(true);
    }

    const renderHeader = () => {
        return (<IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonTitle>Feeds</IonTitle>
                <IonButtons slot="end">
                    <IonButton item-right fill="clear" onClick={handleModal}>
                        <IonIcon text-right icon={more}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>)
    };

    const render = () => {
        let groupCount = 0;
        if (state.loaded) {
            return (
                <>
                    {renderHeader()}
                    <IonContent>
                        <IonList>
                            {state.dateList.map((dateItem) => {
                                groupCount++;
                                return (
                                    <React.Fragment key={dateItem.key}>
                                        <IonItemDivider sticky>
                                            <IonText color="primary">
                                                <b>{dateItem.dateDesc}</b>
                                            </IonText>
                                        </IonItemDivider>
                                        <FeedGroup key={dateItem.key} dateKey={dateItem.key} groupCount={groupCount} showModal={handleModal} />
                                    </React.Fragment>
                                )
                            })}
                        </IonList>
                    </IonContent>
                    <IonModal isOpen={showPrefsModal} onDidDismiss={() => setShowPrefsModal(false)}>
                        <Suspense fallback={<Loading />}>
                            <PreferenceModal
                                dismissModal={() => setShowPrefsModal(false)}
                            />
                        </Suspense>
                    </IonModal>
                    {/* <ShareButtons subject={subject} bodyText={bodyText} fbURL={fbURL}></ShareButtons>  */}
                </>
            )
        }
        else {
            return (
                <Loading />
            )
        }
    }
    return (
        render()
    );
}

export default withRouter(Feeds);





